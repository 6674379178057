export const messages = {
    
    en: {
        home: {
            header: 'Welcome to Tegera arena!'
        },
        order: {
            header: 'My order',
            pageHeader: 'Your order',
            thankYouText: 'Thanks for your order',
            infoText: '(Tap on product to change)',
            infoText2: '(Discounts are deducted in the next step)',
            emptyInfoText: 'No items in the basket yet',
            selectedRestaurant: 'Selected section',
            missing: 'Order is missing',
            pickUpLabel: 'Estimated delivery time',
            statusLabel: 'current status',
            numberLabel: 'Your order number',
            note: "Delivery to:",
            status: {
                calculatedprice: {
                    label: "Verifying order...",
                    description: ""
                },
                added: {
                    label: 'Your order is received',
                    description: "Your order is received and is being processed."
                },
                started: {
                    label: 'Your order is being prepared!',
                    description: "Your order is being prepared."
                },
                finnished: {
                    label: 'Delivering!',
                    description: "Your order is on its way."
                },
                delivered: {
                    label: "Delivered!",
                    description: ""
                },
                rollbacked: {
                    label: "Order cancelled, the reservation on your card has been removed, if you have payed with Swish, please contact the restaurant for a refund.",
                    description: ''
                }
            },
            campaignLabel: 'Campaign'
        },
    },
    sv:{
        home: {
            header: 'Välkommen till Tegera arena!'
        },
        shopSelector: {
            singleShop: {
                start: "Beställ här",
                closed: "Stängt",
                paused: "Stängt för beställning",
                openingHoursHeader: "Dagens öppettider",
            }
        },
        order: {
            header: 'Min beställning',
            pageHeader: 'Din beställning',
            thankYouText: 'Tack för din beställning',
            infoText: '(Tryck på produkt för att ändra)',
            infoText2: '(ev. rabatter dras i nästa steg)',
            emptyInfoText: 'Inga varor i korgen ännu',
            selectedRestaurant: 'Vald sektion',
            missing: 'Beställningen saknas',
            pickUpLabel: 'Beräknas levereras',
            statusLabel: 'Nuvarande status',
            numberLabel: 'Ditt beställningsnummer',
            note: "Levereras till:",
            status: {
                calculatedprice: {
                    label: "Kontrollerar beställning...",
                    description: ""
                },
                added: {
                    label: 'Mottagen',
                    description: "Din beställning är mottagen"
                },
                started: {
                    label: 'Förbereds!',
                    description: "Din beställning förbereds."
                },
                finnished: {
                    label: 'Klart',
                    description: "Din beställning är på väg"
                },
                delivered: {
                    label: "Utlevererad",
                    description: ""
                },
                rollbacked: {
                    label: "Avbruten",
                    description: "Din beställning är avbryten. Reservationen på kortet är borttagen, har du betalat med Swish bör du kontakta restaurangen för återbetalning."
                }
            },
            campaignLabel: 'Erbjudande'
        },
    },
    fi:{},
    no:{},
    ru:{}
}